import React from 'react';
// import GoogleDocsViewer from 'react-google-docs-viewer';

const TermsPage = () => {
    const documentURL =
        'https://docs.google.com/document/d/e/2PACX-1vRccAL4FUs5Qgln_1sDKBzmq4eFoI2JR4P28A7GPHiGc-k6--vYaIADvy1oj07v56zvg6jNhgkSQAl4/pub';
    return (
        <iframe src="https://docs.google.com/document/d/e/2PACX-1vRccAL4FUs5Qgln_1sDKBzmq4eFoI2JR4P28A7GPHiGc-k6--vYaIADvy1oj07v56zvg6jNhgkSQAl4/pub?embedded=true" style={{position:"fixed", top:0, left:0, bottom:0, right:0, width:"100%", height:"100%", border:"none", margin:0, padding:0, overflow:"auto", zIndex:999999}}></iframe>
    );
};
export default TermsPage;
