/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import axios from "axios";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [resptype, setRespType] = React.useState(null);

  const sendMessage = async () => {
    try {
        let response = await axios({
            method : 'POST',
            url : "https://indpai.kumba.menu/restaurantmeetus",
            data : {
                name : name,
                email : email,
                message : message
            }
        });
        setRespType("success");
        setTimeout(() => {
            setRespType(null)
        }, 2000)
    } catch (error) {
        setRespType('error');
        setTimeout(() => {
            setRespType(null);
        }, 2000);
    }
  }

  return (
      <>
          <ExamplesNavbar />
          <LandingPageHeader />
          <div className="main">
              <div className="section text-center">
                  <Container>
                      <Row>
                          <Col className="ml-auto mr-auto" md="8">
                              <h2 className="title">What do we do ?</h2>
                              <h5 className="description">
                                  We deliver anything within Nizamabad to your
                                  doorstep. Fresh Vegetables & fruits, dairy,
                                  grocery, meat, medicines and much more. We
                                  also pick up and deliver your personal stuff
                                  from anywhere to anywhere in Nizamabad
                              </h5>
                              <br />
                              <Button
                                  className=" btn-danger btn-round"
                                  color="info"
                                  href="#demo"
                                  //   onClick={(e) => e.preventDefault()}
                              >
                                  Know More
                              </Button>
                          </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                          <Col md="4">
                              <div className="info">
                                  <div className="icon icon-danger">
                                      <i className="nc-icon nc-sun-fog-29" />
                                  </div>
                                  <div className="description">
                                      <h4 className="info-title">
                                          Early Morning Delivery
                                      </h4>
                                      <p className="description">
                                          Order from our listing and get things
                                          delivered early next day at zero
                                          delivery fee.
                                      </p>
                                      {/* <Button
                                          className="btn-link"
                                          color="info"
                                          href="#pablo"
                                      >
                                          See more
                                      </Button> */}
                                  </div>
                              </div>
                          </Col>
                          <Col md="4">
                              <div className="info">
                                  <div className="icon icon-danger">
                                      <i className="nc-icon nc-bulb-63" />
                                  </div>
                                  <div className="description">
                                      <h4 className="info-title">
                                          Instant Delivery
                                      </h4>
                                      <p>
                                          Order from any of your favourite store
                                          in Nizamabad and we'll get them
                                          delivered to you in 45-60 mins.
                                      </p>
                                      {/* <Button
                                          className="btn-link"
                                          color="info"
                                          href="#pablo"
                                      >
                                          See more
                                      </Button> */}
                                  </div>
                              </div>
                          </Col>
                          <Col md="4">
                              <div className="info">
                                  <div className="icon icon-danger">
                                      <i className="nc-icon nc-chart-bar-32" />
                                  </div>
                                  <div className="description">
                                      <h4 className="info-title">
                                          Discover Around You
                                      </h4>
                                      <p>
                                          Know more about what Nizamabad has to
                                          offer at different stores.
                                      </p>
                                      {/* <Button
                                          className="btn-link"
                                          color="info"
                                          href="#pablo"
                                      >
                                          See more
                                      </Button> */}
                                  </div>
                              </div>
                          </Col>
                          {/* <Col md="3">
                              <div className="info">
                                  <div className="icon icon-info">
                                      <i className="nc-icon nc-sun-fog-29" />
                                  </div>
                                  <div className="description">
                                      <h4 className="info-title">
                                          Reservations & Waitlist
                                      </h4>
                                      <p>
                                          Manage reservations & waitlist for the
                                          guests, allowing customers to make
                                          reservation themselves online.
                                      </p>
                                      {/* <Button
                                          className="btn-link"
                                          color="info"
                                          href="#pablo"
                                      >
                                          See more
                                      </Button> }
                                  </div>
                              </div>
                          </Col> */}
                      </Row>
                  </Container>
              </div>
              <div
                  className="section section-dark text-center"
                  id="darksection"
              >
                  <Container>
                      <h2 className="title">How are we doing ?</h2>
                      <h5 className="description">
                          We're still in our beta phase, setting up the arena
                      </h5>
                      <Row>
                          <Col md="6">
                              <Card className="card-profile card-plain">
                                  {/* <div className="card-avatar">
                                      <a
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          <img
                                              alt="..."
                                              src={
                                                  require('assets/img/faces/clem-onojeghuo-3.jpg')
                                                      .default
                                              }
                                          />
                                      </a>
                                  </div> */}
                                  <CardBody>
                                      <a
                                          href="#darksection"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          <div className="author">
                                              <CardTitle tag="h2">
                                                  Over 200+ SKUs
                                              </CardTitle>
                                              <h6 className="card-category">
                                                  for EARLY MORNING DELIVERY
                                              </h6>
                                          </div>
                                      </a>
                                      <p className="card-description text-center">
                                          As our selection grows, we offer
                                          customers more choices and convenience
                                          to get excatly what they want without
                                          any hassle
                                      </p>
                                  </CardBody>
                              </Card>
                          </Col>
                          <Col md="6">
                              <Card className="card-profile card-plain">
                                  {/* <div className="card-avatar">
                                      <a
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          <img
                                              alt="..."
                                              src={
                                                  require('assets/img/faces/joe-gardner-2.jpg')
                                                      .default
                                              }
                                          />
                                      </a>
                                  </div> */}
                                  <CardBody>
                                      <a
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          <div className="author">
                                              <CardTitle tag="h2">
                                                  Over 50+ partners
                                              </CardTitle>
                                              <h6 className="card-category">
                                                  FOR INSTANT DELIVERY
                                              </h6>
                                          </div>
                                      </a>
                                      <p className="card-description text-center">
                                          We've partnered iwth local vendors,
                                          restaurants, stores and many more to
                                          provide the convenience of delivering
                                          things right away.
                                      </p>
                                  </CardBody>
                              </Card>
                          </Col>
                          {/* <Col md="4">
                              <Card className="card-profile card-plain">
                                  {/* <div className="card-avatar">
                                      <a
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          <img
                                              alt="..."
                                              src={
                                                  require('assets/img/faces/erik-lucatero-2.jpg')
                                                      .default
                                              }
                                          />
                                      </a>
                                  </div> }
                                  <CardBody>
                                      <a
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          <div className="author">
                                              <CardTitle tag="h2">
                                                  2,00,000+
                                              </CardTitle>
                                              <h6 className="card-category">
                                                  HAPPY CUSTOMERS
                                              </h6>
                                          </div>
                                      </a>
                                      <p className="card-description text-center">
                                          When you save customers 25-30 mins of
                                          their waiting time on their visit,
                                          just by making things convenient, you
                                          make a lot of customers happy
                                      </p>
                                  </CardBody>
                              </Card>
                          </Col> */}
                          <Col md="12">
                              <Card className="card-profile card-plain">
                                  {/* <div className="card-avatar">
                                      <a
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          <img
                                              alt="..."
                                              src={
                                                  require('assets/img/faces/erik-lucatero-2.jpg')
                                                      .default
                                              }
                                          />
                                      </a>
                                  </div> */}
                                  <CardBody>
                                      <a
                                          href="#pablo"
                                          onClick={(e) => e.preventDefault()}
                                      >
                                          <div className="author">
                                              <CardTitle tag="h2">
                                                  And we also have a section to
                                                  know more about offers and
                                                  events in Nizamabad
                                              </CardTitle>
                                              <h6 className="card-category">
                                                  TRY IT TO BELIEVE IT
                                              </h6>
                                          </div>
                                      </a>
                                      {/* <p className="card-description text-center">
                                          When you save customers 25-30 mins of
                                          their waiting time on their visit,
                                          just by making things convenient, you
                                          make a lot of customers happy
                                      </p> */}
                                  </CardBody>
                              </Card>
                          </Col>
                      </Row>
                  </Container>
              </div>
              <div className="section landing-section" id="demo">
                  <Container>
                      <Row>
                          <Col className="ml-auto mr-auto" md="8">
                              <h2 className="text-center">Need a Demo?</h2>
                              <Form className="contact-form">
                                  <Row>
                                      <Col md="6">
                                          <label>Name</label>
                                          <InputGroup>
                                              <InputGroupAddon addonType="prepend">
                                                  <InputGroupText>
                                                      <i className="nc-icon nc-single-02" />
                                                  </InputGroupText>
                                              </InputGroupAddon>
                                              <Input
                                                  placeholder="Name"
                                                  type="text"
                                                  onChange={(e) =>
                                                      setName(e.target.value)
                                                  }
                                              />
                                          </InputGroup>
                                      </Col>
                                      <Col md="6">
                                          <label>Email</label>
                                          <InputGroup>
                                              <InputGroupAddon addonType="prepend">
                                                  <InputGroupText>
                                                      <i className="nc-icon nc-email-85" />
                                                  </InputGroupText>
                                              </InputGroupAddon>
                                              <Input
                                                  placeholder="Email"
                                                  type="text"
                                                  onChange={(e) =>
                                                      setEmail(e.target.value)
                                                  }
                                              />
                                          </InputGroup>
                                      </Col>
                                  </Row>
                                  <label>Message</label>
                                  <Input
                                      placeholder="Tell us your wants and needs..."
                                      type="textarea"
                                      rows="4"
                                      onChange={(e) =>
                                          setMessage(e.target.value)
                                      }
                                  />
                                  <Row>
                                      <Col className="ml-auto mr-auto" md="4">
                                          <Button
                                              className="btn-fill"
                                              color="danger"
                                              size="lg"
                                              onClick={async (e) => {
                                                  try {
                                                      e.preventDefault();
                                                      await sendMessage();
                                                  } catch (err) {
                                                      return Promise.reject(
                                                          err
                                                      );
                                                  }
                                              }}
                                          >
                                              Send Message
                                          </Button>
                                          {resptype ? (
                                              resptype === 'success' ? (
                                                  <p
                                                      style={{
                                                          color: 'darkgreen',
                                                      }}
                                                  >
                                                      Message Sent. Our team
                                                      will reach out shortly
                                                  </p>
                                              ) : resptype === 'error' ? (
                                                  <p
                                                      style={{
                                                          color: 'darkred',
                                                      }}
                                                  >
                                                      Something went wrong.
                                                      Please try again
                                                  </p>
                                              ) : null
                                          ) : null}
                                      </Col>
                                  </Row>
                                  <h2 className="text-center">OR</h2>
                                  <Row>
                                      <Col className="ml-auto mr-auto" md="4">
                                          <Button
                                              className="btn-fill"
                                              style={{
                                                  backgroundColor: 'darkgreen',
                                              }}
                                              //   color="success"
                                              size="lg"
                                              href="https://wa.me/917207854693"
                                              //   onClick={async (e) => {
                                              //       try {
                                              //           e.preventDefault();
                                              //           await sendMessage();
                                              //       } catch (err) {
                                              //           return Promise.reject(
                                              //               err
                                              //           );
                                              //       }
                                              //   }}
                                          >
                                              Whatsapp Us
                                          </Button>
                                      </Col>
                                  </Row>
                              </Form>
                          </Col>
                      </Row>
                  </Container>
              </div>
          </div>
          <DemoFooter />
      </>
  );
}

export default LandingPage;
