/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
      <>
          <div
              style={{
                  backgroundImage:
                      'url(' +
                      require('assets/img/daniel-olahh1.webp').default +
                      ')',
              }}
              className="page-header"
              data-parallax={true}
              ref={pageHeader}
              id="landingtop"
          >
              <div className="filter" />
              <Container>
                  <div className="motto text-center">
                      <h1>MY NIZAMABAD</h1>
                      <h3>Get everything delivered</h3>
                      <br />
                      {/* <Button
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              className="btn-round mr-1"
              color="neutral"
              target="_blank"
              outline
            >
              <i className="fa fa-play" />
              Watch video
            </Button> */}
                      <Button
                          className="btn-round"
                          color="neutral"
                          type="button"
                          outline
                          href="https://play.google.com/store/apps/details?id=in.mynzb.android"
                      >
                          Download Now
                      </Button>
                  </div>
              </Container>
          </div>
      </>
  );
}

export default LandingPageHeader;
